import React from 'react'
import Layout from '../components/layout'
import { Column } from '../components/Column'

const IndexPage = () => (
  <Layout>
    <Column>
      <h3>Music Company is a record label from Melbourne, Australia.</h3>
      <ul>
        <li>
          <a href="https://musiccompany.bandcamp.com/">
            <strong>Bandcamp</strong>
          </a>
        </li>
        <li>
          <a href="https://soundcloud.com/musiccompanyco">
            <strong>SoundCloud</strong>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/musiccompany/">
            <strong>Instagram</strong>
          </a>
        </li>
        <li>
          <a href="https://open.spotify.com/user/zc1ez6iiqtvc26y3s4hvvskp1?si=R1O0mlLiTpqoKr2fzfwpxA">
            <strong>Spotify</strong>
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCgYF54g-84JaGzGqWX8mGiA">
            <strong>YouTube</strong>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/musiccompany.co">
            <strong>Facebook</strong>
          </a>
        </li>
        <li>
          <a href="mailto:hello@musiccompany.co">
            <strong>Contact</strong>
          </a>
        </li>
      </ul>
    </Column>
  </Layout>
)

export default IndexPage
